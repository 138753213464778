import { Dispatch, Fragment, ReactNode, SetStateAction, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { X } from '@/public/icons/account/icons';

export default function ReebeloSatisfaction({
  showModal,
  setShowModal,
  children,
  title,
}: Readonly<{
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  title: string;
  children: ReactNode;
}>) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        className="relative z-50 text-sm"
        initialFocus={cancelButtonRef}
        onClose={setShowModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 flex justify-end">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative w-screen max-w-[500px] bg-white shadow-xl">
              <Dialog.Panel className="flex h-full flex-col overflow-hidden">
                <Dialog.Title
                  as="div"
                  className="relative flex items-center justify-between p-4 shadow"
                >
                  <h3 className="text-lg font-bold">{title}</h3>
                  <button
                    className="text-gray-700/50 transition duration-150 ease-in-out hover:text-gray-700"
                    onClick={() => setShowModal(false)}
                  >
                    <X className="h-5 w-5" />
                  </button>
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
