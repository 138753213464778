import { omit } from 'lodash-es';
import { useBuybackContext } from '@/lib/buyback/BuybackContext';
import { VariantsT } from '@/lib/buyback/types';

const QuoteSummaryContent = () => {
  const { summaryQuote } = useBuybackContext();

  if (summaryQuote == null) return null;

  return (
    <div>
      <h3 className="text-lg font-bold">Quote Summary</h3>
      <div className="mt-3 flex items-center justify-between py-2">
        <div>Model</div>
        <div>{summaryQuote.model}</div>
      </div>

      {Object.keys(omit(summaryQuote, 'model', 'image', 'total')).map(
        (variant) => {
          const generateLabel = (v: VariantsT) => {
            if (['housing', 'screen'].includes(v)) return `${v} Condition`;
            if (v === 'condition') return `Fully functional`;

            return v;
          };
          const label = generateLabel(variant as VariantsT);

          return (
            <div className="flex items-center justify-between border-t py-2">
              <div className="capitalize">{label}</div>
              <div>{summaryQuote[variant as VariantsT]}</div>
            </div>
          );
        },
      )}
    </div>
  );
};

export default QuoteSummaryContent;
