import { BuybackCustomer } from '@/lib/buyback/types';

enum ConditionType {
  NEW = 'new',
  USED = 'used',
}

enum ConditionTypeAU {
  AS_NEW = 'as new',
  WORKING = 'working',
  DEAD = 'dead',
}

export enum DeviceCategory {
  PHONE = 'phone',
  TABLET = 'tablet',
  WATCH = 'watch',
  LAPTOP = 'laptop',
}

export type Product = {
  productId: string;
  category: DeviceCategory;
  brand: string;
  model: string;
  storage: string;
  price: number;
  deductions?: VendorDeductions;
  cpu?: string;
  gpu?: string;
  ram?: string;
  watchCaseMaterial?: string;
  connectivity?: string;
  carrier?: string;
  condition: ConditionType | ReebeloBuybackConditionT;
  rid?: string;
};
export namespace OrderStatus {
  export enum SG {
    PAID = 'Paid',
    NO_SHOW = 'No Show',
    REJECTED = 'Quote rejected',
    SCHEDULED = 'Scheduled',
    CANCELLED = 'Cancelled',
    SPAM = 'Spam',
  }
  export enum AU {
    RECEIVED = 'Received',
    CANCELLED = 'Cancelled',
    NOT_RECIEVED = 'Not received',
    REJECTED = 'Quote rejected',

    PAID = 'Paid',
    NEW_SUBMISSION = 'New submission',
    RETURN_INITIATED = 'Return initiated',
    RETURN_RECEIVED = 'Return received',
    CUSTOMER_SENT = 'Customer sent',
    UNDER_INSPECTION = 'Under inspection',
    COUNTER_OFFER = 'Counter offer',
    COUNTER_OFFER_MADE = 'Counter offer made',
  }
  export enum US {
    PENDING_VENDOR_APPROVAL = 'Pending vendor approval',
    VENDOR_APPROVED = 'Vendor approved',
    REJECTED = 'Vendor rejected',
    COUNTER_OFFER_MADE = 'Counter offer made',
    COUNTER_OFFER_REJECTED = 'Counter offer rejected',
    RECEIVED_BY_VENDOR = 'Received by vendor',
    OTW_TO_VENDOR = 'On the way to vendor',
    PAID = 'Paid',
    PAYMENT_PENDING = 'Payment pending',
    PAID_MANUALLY = 'Paid manually',
    NEW_SUBMISSION = 'New submission',
    RETURN_INITIATED = 'Return initiated',
    RETURN_RECEIVED = 'Return received',
    UNDER_INSPECTION = 'Under inspection',
    CANCELLED = 'Cancelled',
  }
  export type All = SG | AU | US;
}
export namespace CollectionMethod {
  export enum SG {
    DOORSTEP = 'Doorstep',
    DROPOFF = 'Dropoff',
  }
  export enum AU {
    SATCHEL = 'Satchel',
    AU_POST = 'Australia Post',
  }
  export enum US {
    MAIL_IN = 'UPS Shipping',
  }
  export type All = SG | AU | US;
}

enum DayOfWeek {
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat',
  Sun = 'Sun',
}

type BankTransferPaymentDetails = {
  amount?: {
    type: string;
    value: string;
  };
  creditorAccount?: {
    identification: string;
  };
  creditor?: {
    name: string;
    type: string;
    postalAddress: {
      addressLine: string[];
      city: string;
      country: string;
    };
  };
  creditorAgent?: {
    branch: {
      identification: string;
      name: string;
    };
    institution: {
      identification: string;
      name: string;
    };
  };
  paymentMethod?: string;
  paymentType?: {
    serviceLevel: string;
  };
  paymentIdentification?: {
    endToEndIdentification: string;
    instructionIdentification: string;
  };
  requestedExecutionDate?: string;
};

type PaypalPaymentDetails = {
  paypalEmail?: string | null;
  paypalMobile?: string | null;
};

type ZellePaymentDetails = {
  zelleEmail?: string | null;
  zelleMobile?: string | null;
};

type PaymentDetailsUS =
  | BankTransferPaymentDetails
  | PaypalPaymentDetails
  | ZellePaymentDetails; // More types can be added in the future

type ShippingDetailsUS = {
  trackingNumber?: string;
  token?: string;
  trackingLink?: string;
  totalCharges?: number;
};

type ReturnShippingDetailsUS = {
  carrier?: string;
  trackingNumber?: string;
  trackingLink?: string;
};

type PaymentDetailsT = {
  amount: {
    type: '';
    value: '';
  };
  creditorAccount: {
    identification: '';
  };
  creditor: {
    name: '';
    type: '';
    postalAddress: {
      addressLine: [''];
      city: '';
      country: '';
    };
  };
  creditorAgent: {
    branch: {
      identification: '';
      name: '';
    };
    institution: {
      identification: '';
      name: '';
    };
  };
  paymentMethod: 'Paypal' | 'Zelle' | '';
  paymentType: {
    serviceLevel: '';
  };
  paymentIdentification: {
    endToEndIdentification: '';
    instructionIdentification: '';
  };
  requestedExecutionDate: '';
  paypalEmail?: string;
  paypalMobile?: string;
  zelleEmail?: string;
  zelleMobile?: string;
};

type CollectionDetailsT = {
  email: string;
  suburb: string;
  addressLine2?: string;
  postCode: string;
  mobile: string;
  address: string;
  state: string;
  firstName: string;
  lastName: string;
};

export enum BuybackSalesChannel {
  BUYBACK = 'Buyback',
  TRADE_IN = 'Trade-In',
}

export type BuybackQuoteUS = {
  customer: BuybackCustomer | null;
  otherIssues: string[];
  originalAccessories: string[];
  storage?: string;
  housing: HousingCondition | '';
  screen: ScreenCondition | '';
  finalQuote: number;
  deviceName: string;
  brand: string;
  category: DeviceCategory | '';
  vendorSlug: string;
  vendorName: string;
  collectionType: 'UPS Shipping';
  collectionDetails: CollectionDetailsT | null;
  paymentMethod: string;
  paymentDetails: PaymentDetailsT;
  image: string;
  id: string;
  productId: string;
  hasSubmit: boolean;
  orderId: string;
  deviceIssues: Record<string, boolean>;
  rid: string;
  condition: ReebeloBuybackConditionT | '';
  carrier: string;
  deviceFunctional: 'Yes' | 'No' | '';
  unlockedStatus: string;
  vendorMailInLocation: {
    addressline: string;
    stateprovincecode: string;
    city: string;
    postalcode: string;
    countrycode: string;
  } | null;
  vendorCommission: number;
  watchCaseMaterial?: string;
  cpu?: string;
  ram?: string;
  connectivity?: string;
  marketingUpdates: boolean;
  token: string;
  shippingDetails?: ShippingDetailsUS;
  batteryHealth: string;
  salesChannel?: string;
};

export type Order = {
  airtableRecordId?: string;
  orderId: string;
  productId: string; // old orders don't have productId
  quote: number;
  initialQuote?: number;
  collection: {
    date?: string;
    time?: string;
    timestamp?: number; // timestamp used by elasticsearch to sort order. Represent the date of the delivery at the time of the start of the slot
    method: CollectionMethod.All; // dropoff, doorstep, satchel, aust post, ups
    outlet?: string; // the outlet that will recieve the device
  };
  customer: {
    fullName: string;
    address: string;
    email: string;
    phone: string;
  };
  device: {
    storage?: string;
    model: string;
    brand?: string;
    imei?: string;
    category: DeviceCategory; // phone or tablet
    cpu?: string;
    gpu?: string;
    ram?: string;
    batteryHealth?: string;
    carrier?: string;
    rid?: string;
    condition?: ReebeloBuybackConditionT;
  };
  submissionDate: string;
  cancelledDate?: string;
  condition:
    | Partial<Record<DeviceIssue.SG | DeviceIssue.ModelSpecific, boolean>>
    | Partial<Record<DeviceIssue.AU | DeviceIssue.ModelSpecific, boolean>>;
  vendor: string;
  vendorName: string;
  vendorCommission?: number;
  status: OrderStatus.SG | OrderStatus.AU | OrderStatus.US;
  paid: number | null;
  paymentMethod?: {
    name: string; // paypal, bank transfer...
    paypal?: { email: string } & PaypalPaymentDetails;
    bankTransfer?: {
      bankName: string;
      accountName: string;
      bsb: string;
      accountNumber: string;
    } & BankTransferPaymentDetails;
    cheque?: { name: string };
    payId?: { id: string };
    zelle?: ZellePaymentDetails;
  } & PaymentDetailsUS;
  conditionType: ConditionType | ConditionTypeAU;
  comment?: string;
  orderHandle?: string;
  adminComment?: string;
  shippingDetails?: ShippingDetailsUS;
  returnInitiatedDetails?: ReturnShippingDetailsUS;
  counterOffer?: number;
  counterOfferComment?: string;
  originalAccessories?: string[];
  otherIssues?: string[];
  screenCondition?: string;
  housingCondition?: string;
};

// // Use the ones in @buyback/api stack for database
const PARTITION_KEY = 'partition-key';
const SORT_KEY = 'sort-key';
const LSI_1_SK = 'lsi-sk';
const LSI_2_SK = 'lsi-2-sk';
const LSI_3_SK = 'lsi-3-sk';
const LSI_4_SK = 'lsi-4-sk';

type TimeSlot = {
  slot: string[]; // should be [start, end]
  maxSlots: number;
  isClosed: boolean;
};

type PrimaryKey = {
  [PARTITION_KEY]: string;
  [SORT_KEY]: string;
};

type LsiIndex = {
  [LSI_1_SK]?: string;
  [LSI_2_SK]?: string;
  [LSI_3_SK]?: string;
  [LSI_4_SK]?: string;
};

type Deduction = {
  type: 'flat' | 'percentage';
  value: number;
};

// For doorstep
type DayTime = {
  day: DayOfWeek;
  timeslots: TimeSlot[];
};

// For dropoff
type LocationDayTime = {
  location: string;
  dayTime: DayTime[];
};

// For Mail-in
type MailInLocation = {
  addressline: string;
  city: string;
  postalcode: string;
  stateprovincecode: string;
  countrycode: string;
};

export enum HousingCondition {
  Minor = 'Housing Minor',
  Moderate = 'Housing Moderate',
  Cracked = 'Housing Cracked',
  Flawless = 'Housing Flawless',
}

export enum ScreenCondition {
  Cracked = 'Screen Cracked',
  Moderate = 'Screen Moderate',
  Minor = 'Screen Minor',
  Flawless = 'Screen Flawless',
}

export type DeviceFunctionalT = '' | 'Yes' | 'No';

namespace DeviceIssue {
  export enum ModelSpecific {
    'Warranty is less than 2 months' = 'Warranty is less than 2 months',
  }
  export enum SG {
    'Housing Minor' = 'Housing Minor',
    'Housing Moderate' = 'Housing Moderate',
    'Housing Cracked' = 'Housing Cracked',
    'Screen Cracked' = 'Screen Cracked',
    'Screen Moderate' = 'Screen Moderate',
    'Screen Minor' = 'Screen Minor',
    'Unable PowerOn' = 'Unable PowerOn',
    'Screen Damaged' = 'Screen Damaged', // "LED Display defective"
    'Faulty Camera' = 'Faulty Camera',
    'Touch Screen Faulty' = 'Touch Screen Faulty',
    'Fingerprint/Face ID faulty' = 'Fingerprint/Face ID faulty',
    'Missing Wall Charger' = 'Wall Charger',
    'Missing Earphone' = 'Earphone',
    'Missing Charging Cable' = 'Charging Cable',
    'Missing Box' = 'Box',
    'Missing Power Cord' = 'Power Cord',
    'Missing Charger / Adapter' = 'Charger / Adapter',
    'No Invoice' = 'No Invoice',
    'No Sealed Box' = 'No Sealed Box',
    'No Singapore Set' = 'No Singapore Set',
    'No Activation Set' = 'No Activation Set',
    'Doorstep Fee' = 'Doorstep Fee',
    // For smartwatches
    'Gyroscope/Accelerometer not working' = 'Gyroscope/Accelerometer not working',
    'Watch Bluetooth Faulty' = 'Watch Bluetooth Faulty',
    'Watch Buttons Faulty' = 'Watch Buttons Faulty',
    'Watch Battery Faulty' = 'Watch Battery Faulty',
    'ICloud Locked' = 'ICloud Locked',
    'Keyboard and/or Backlight Faulty' = 'Keyboard and/or Backlight Faulty',
    'Touchbar Issues' = 'Touchbar Issues',
    'Trackpad Faulty' = 'Trackpad Faulty',
    'Speaker Faulty' = 'Speaker Faulty',
    'One or more Ports are faulty' = 'One or more Ports are faulty',
    '[Laptop] Software Issues' = '[Laptop] Software Issues',
    'My issue is not listed' = 'My issue is not listed',
    'Battery health 91% & above' = 'Battery health 91% & above',
    'Battery health 85-90%' = 'Battery health 85-90%',
    'Battery health 80-84%' = 'Battery health 80-84%',
    'Battery health 79% and below' = 'Battery health 79% and below',
  }
  export enum AU {
    '[Device condition] New' = '[Device condition] New',
    '[Device condition] Working' = '[Device condition] Working',
    '[Device condition] Dead' = '[Device condition] Dead',
    'Power On/Off button faulty' = 'Power On/Off button faulty',
    'Software Issues' = 'Software Issues',
    'Reception Issues' = 'Reception Issues',
    'SIM Tray Missing' = 'SIM Tray Missing',
    'Camera is Faulty' = 'Camera is Faulty',
    'Speaker or Microphone Issues' = 'Speaker or Microphone Issues',
    'Volume Button Faulty' = 'Volume Button Faulty',
    'Cracked Front Glass Display Panel' = 'Cracked Front Glass Display Panel',
    'Cracked Back Glass Panel' = 'Cracked Back Glass Panel',
    'Back Camera Glass Cracked' = 'Back Camera Glass Cracked',
    'Other Cosmetic Damage' = 'Other Cosmetic Damage',
  }
  // Any US deductions will go here
  export enum US {
    'Housing Minor' = 'Housing Minor',
    'Housing Moderate' = 'Housing Moderate',
    'Housing Cracked' = 'Housing Cracked',
    'Screen Cracked' = 'Screen Cracked',
    'Screen Moderate' = 'Screen Moderate',
    'Screen Minor' = 'Screen Minor',
    'Unable PowerOn' = 'Unable PowerOn',
    'Screen Damaged' = 'Screen Damaged', // "LED Display defective"
    'Faulty Camera' = 'Faulty Camera',
    'Touch Screen Faulty' = 'Touch Screen Faulty',
    'Fingerprint/Face ID faulty' = 'Fingerprint/Face ID faulty',
    'Missing Wall Charger' = 'Wall Charger',
    'Missing Earphone' = 'Earphone',
    'Missing Charging Cable' = 'Charging Cable',
    'Missing Box' = 'Box',
    'Missing Power Cord' = 'Power Cord',
    'Missing Charger / Adapter' = 'Charger / Adapter',
    'SIM Tray Missing' = 'SIM Tray Missing',
    'No Invoice' = 'No Invoice',
    'No Sealed Box' = 'No Sealed Box',
    'Doorstep Fee' = 'Doorstep Fee',
    // For smartwatches
    'Gyroscope/Accelerometer not working' = 'Gyroscope/Accelerometer not working',
    'Watch Bluetooth Faulty' = 'Watch Bluetooth Faulty',
    'Watch Buttons Faulty' = 'Watch Buttons Faulty',
    'Watch Battery Faulty' = 'Watch Battery Faulty',
    'ICloud Locked' = 'ICloud Locked',
    'Keyboard and/or Backlight Faulty' = 'Keyboard and/or Backlight Faulty',
    'Touchbar Issues' = 'Touchbar Issues',
    'Trackpad Faulty' = 'Trackpad Faulty',
    'Speaker Faulty' = 'Speaker Faulty',
    'One or more Ports are faulty' = 'One or more Ports are faulty',
    '[Laptop] Software Issues' = '[Laptop] Software Issues',
    'My issue is not listed' = 'My issue is not listed',
    'Battery health 91% & above' = 'Battery health 91% & above',
    'Battery health 85-90%' = 'Battery health 85-90%',
    'Battery health 80-84%' = 'Battery health 80-84%',
    'Battery health 79% and below' = 'Battery health 79% and below',
    'Power On/Off button faulty' = 'Power On/Off button faulty',
  }
  export type All = AU | SG | (AU & SG) | ModelSpecific;
  export const all = {
    ...DeviceIssue.AU,
    ...DeviceIssue.SG,
    // ...DeviceIssue.US,
    ...DeviceIssue.ModelSpecific,
  };
}

type DeviceIssueMapped = Partial<Record<DeviceIssue.All, boolean>>;

type VendorDeductions = Partial<Record<DeviceIssue.All, Deduction>>;

type DeviceSpecificIssues = Record<Partial<DeviceIssue.ModelSpecific>, boolean>;

type Dictionnary<T extends string, R> = Partial<Record<T, R>>;

enum VendorCountry {
  AU = 'AU',
  SG = 'SG',
  US = 'US',
}

// Currently only used in the US
export enum ReebeloBuybackConditionT {
  Good = 'Good',
  Excellent = 'Excellent',
  New = 'New',
  Damaged = 'Damaged',
}

// Order of the conditions should be from worst to best (DO NOT CHANGE)
const ConditionDeductionMappings: Record<
  ReebeloBuybackConditionT,
  DeviceIssue.US[]
> = {
  [ReebeloBuybackConditionT.Damaged]: [
    DeviceIssue.US['Housing Cracked'],
    DeviceIssue.US['Screen Cracked'],
    DeviceIssue.US['Unable PowerOn'],
    DeviceIssue.US['Watch Buttons Faulty'], // Only for Smartwatches
    DeviceIssue.US['Keyboard and/or Backlight Faulty'],
    DeviceIssue.US['Power On/Off button faulty'],
  ],
  [ReebeloBuybackConditionT.Good]: [
    DeviceIssue.US['Housing Moderate'],
    DeviceIssue.US['Screen Moderate'],
    DeviceIssue.US['SIM Tray Missing'],
  ],
  [ReebeloBuybackConditionT.Excellent]: [
    DeviceIssue.US['Housing Minor'],
    DeviceIssue.US['Screen Minor'],
  ],
  [ReebeloBuybackConditionT.New]: [],
};

export type VendorDetails = PrimaryKey & {
  vid: string;
  displayName: string;
  slug: string;
  rating: number;
  postalCode: string[];
  logoUrl: string;
  doorStepDetails: DayTime[];
  dropOffDetails: LocationDayTime[];
  mailInLocation?: MailInLocation;
  commission?: number;
  advanceBookDay?: number;
  'Doorstep threshold price': number;
  'Max Quotes per day': number;
  deductions: VendorDeductions;
  country: VendorCountry;
  collectionMethods:
    | Dictionnary<CollectionMethod.SG, boolean>
    | Dictionnary<CollectionMethod.AU, boolean>
    | Dictionnary<CollectionMethod.US, boolean>;
};

interface BaseProductAttributes {
  storage: string;
  pimPSKU?: string;
  ram?: string;
  cpu?: string;
  gpu?: string;
  condition: ConditionType | ReebeloBuybackConditionT; // ConditionType: non-us , ReebeloBuybaconDontiionT : us
  carrier?: string; // Unlocked or locked status
  rid?: string;
}

type BaseProduct = {
  productId: string;
  brand: string;
  model: string;
  category: DeviceCategory;
  image: string;
  bestVendorPrice: number;
  slug: string;
  displayOrder: number;
  deviceSpecificIssues?: DeviceSpecificIssues;
  cpuDescription?: string;
  gpuDescription?: string;
} & BaseProductAttributes;

export type ProductDb = PrimaryKey & LsiIndex & BaseProduct;

export const deduceConditionFromDeviceIssues = (
  deviceIssues: DeviceIssueMapped,
  functionalStatus: string,
) => {
  if (functionalStatus === 'No') return ReebeloBuybackConditionT.Damaged;
  const selectedIssues = Object.entries(deviceIssues)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_issue, flag]) => flag)
    .map((issue) => issue[0]);

  const conditionDeduced = Object.entries(ConditionDeductionMappings).find(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_condition, issues]) => issues.some((i) => selectedIssues.includes(i)),
  )?.[0];

  return (conditionDeduced ||
    ReebeloBuybackConditionT.Excellent) as ReebeloBuybackConditionT;
};
